.rulespage{
    text-align: center;
    /*height: 550px;
    max-width: 800px;*/
    margin: 0 auto;
    padding: 60px 0 100px 0;
    background: linear-gradient(90.21deg, rgba(21, 18, 97, 0.5) -5.91%, rgba(0, 34, 81, 0.5) 111.58%);
}

.rulespage h1{
    text-align: center; 
    padding:30px;
}
  
  .rulespage .accordion-item {
    border: none;
    border-radius: 10px;
    background-color: #f8f9fa;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 50px;
    /*max-width: 500px;*/

  }
  
  .rulespage .accordion-header {
    background-color: #fff;
    color: #fff;
    font-weight: bold;
    padding: 15px;
    border-radius: 10px;
  }
  
  .rulespage .accordion-body {
    padding: 20px;
    color: #333;

  }
  
  .rulespage .accordion-body p {
    margin-bottom: 0;
  }
  
  .rulespage .accordion-button {
    background-color: transparent;
    color: #007bff;
    font-weight: bold;
    border: none;
  }
  
  .rulespage .accordion-button:not(.collapsed) {
    color: #0056b3;
  }
  /*
  .rulespage .accordion-button:not(.collapsed)::after {
    content: '-';
  }
  
  .rulespage .accordion-button.collapsed::after {
    content: '+';
  }*/
  