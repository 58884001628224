.player-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #81d4fa; /* Light blue background color */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 2px 5px #c9c9c9;
  }
  
  .player-name {
    color: #1a1a1a;
    text-shadow: 2px 2px #c9c9c9;
  }
  
  .player-country {
    color: #b71c1c; /* Bright red color */
    font-style: italic;
  }
  
  .player-type {
    color: #33691e; /* Vibrant green color */
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .player-franchise {
    color: #3f51b5; /* Deep purple color */
    text-transform: uppercase;
    letter-spacing: 2px;
  }