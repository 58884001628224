.linegraphpage{
    margin-top: 0;
    padding: 10px 0 0px 0;
    background: linear-gradient(90.21deg, rgba(21, 18, 97, 0.5) -5.91%, rgba(0, 34, 81, 0.5) 111.58%);
    height: 100vh;
  }

  .linegraphpage .linegraphcontainer{
  margin-top: 160px;
  margin-left: 10px 20px;
  margin-bottom: -160px;
  background: #d5c4c4;
  width: 100%;
  height: 75vh;
  display:grid;
  flex-direction: column;
  border-radius: 10px;
  overflow: auto;
  }

  .linegraphpage .linegraphcontainer .ant-breadcrumb-link{
    display: flex;
    justify-content: left;
    font-weight: bold;
    color: rgb(248, 70, 5);
    margin-bottom: -10px;
  }

  .linegraphpage .linegraphcontainer .chart{
    overflow: auto;
  }