.snakepage {
    margin-top: 0;
    padding: 10px 0 0 0;
    background: linear-gradient(90.21deg, rgba(21, 18, 97, 0.5) -5.91%, rgba(0, 34, 81, 0.5) 111.58%);
    height: 105vh;
}

.snakecontainer {
    margin-top: 160px;
    background: #d5c4c4;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    padding: 20px;
}

.tablecontainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-grow: 1;
}

.main-content {
    flex: 0 0 72%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sidebar {
    flex: 0 0 28%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
}

.ag-theme-alpine {
    width: 100%;
    height: 70vh;
}

.owner-table-item {
    width: 100%;
    height: 72vh;
    display: flex;
    align-items:flex-start;
    justify-content: center;
}

.owner-table-item > div {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 1px;
}

h1 {
    font-size: 25px;
    margin-bottom: 5px;
}

.timer {
    font-size: 25px;
    margin-bottom: 5px;
    font-weight: bold;
}

.controls {
    margin-bottom: 5px;
}

.controls input {
    border-radius: 5px;
    margin-bottom: 5px;
    width: 250px;
    margin: 5px;
    height: 50px;
}

.controls button {
    border-radius: 5px;
    margin-bottom: 5px;
    margin: 5px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.controls button:hover {
    background-color: #45a049;
}

.card {
    margin: 0.5rem 0;
    padding: 1rem;
    border: 1px solid black;
    border-radius: 10px;
    width: 445px;
    text-align: center;
}
