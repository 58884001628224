.footer {
    padding: 20px 0;
    background-color: #121212;
    /*
    position: absolute;
    bottom: 0;
    width: 100%;*/
    color: white;
  }
  
  .footer img {
    width: 19%;
  }
  .footer p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }
  .h-footer {
    /*line-height: 2;*/
    display: flex;
    align-items: center; /* Vertically center the content */
    justify-content: space-between; /* Align items with equal space between them */
  }
  /*
  @media only screen and (max-width: 768px) {
    .logo-xs {
      text-align: center!important;
      width: 26% !important;
    }
    .react-multiple-carousel__arrow{
      top: 20%;
    }
    img.logo-nav {
      width: 200% !important;
    }
   .top {
      display: none;
    }*/
    /*
    .navbar-nav {
      background: linear-gradient(90.21deg, #a7357280 -5.91%, #492fbc80 111.58%);
    }*/
 /* }
  
  @media (max-width: 480px) {
  
  
  }*/
  