.carouselpage{
    text-align: center;
    /*height: 550px;*/
    padding: 60px 0 100px 0;
    background: linear-gradient(90.21deg, rgba(21, 18, 97, 0.5) -5.91%, rgba(0, 34, 81, 0.5) 111.58%);
}

.carouselpage h1{
    text-align: center; 
    padding:30px;
}

.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px; /* Add rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Add a subtle shadow */
    overflow: hidden;
    
  }
  
  .carousel-card {
    width: 400px;
    margin: 0 auto; /* Center the card horizontally */
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add some shadow for depth */
    transition: transform 0.6s ease-in-out;
  }

  .carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(-100%); /* Adjust transition position as needed */
}

.carousel-item-next,
.carousel-item-prev,
.carousel-inner {
  transition: transform 0.6s ease-in-out; /* Apply transition to inner elements */
}
  
  .carousel-card .card-img-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .carousel-card .card-body {
    display: flex;  /* Use flexbox for vertical centering */
    flex-direction: column;  /* Arrange elements in a column */
    justify-content: center;  /* Center content vertically */
    align-items: center;  /* Center content horizontally (optional) */
    height: 400px;  /* Ensure card body takes up full height */
    width: 400px;
  }

  .carousel-card .first.card-body {
    background-color: lightpink;
  }
  
  .carousel-card .second.card-body {
    background-color: lightsalmon;
  }

  .carousel-card .third.card-body {
    background-color: lightcoral;
  }
  /*
  .carousel-card .card-body {
    padding: 20px;
  }
  */
  .carousel-card .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .carousel-card .card-text {
    font-size: 1rem;
    color: #666;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .carousel-card {
      max-width: 100%; /* Adjust width for smaller screens */
      
    }
  }
  